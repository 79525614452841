import axios from "axios";
import JwtService from "@/core/services/jwt.service";

export default function setup() {
  axios.interceptors.request.use(
    function (config) {
      const proxyPath = ["/customer"];
      const isProxy = config.url.includes(proxyPath);
      config.headers = {
        Authorization: `Bearer ${JwtService.getToken()}`,
        Accept: "application/json",
      };
      if (!isProxy) {
        const payloadMethods = ["put", "post", "delete"];
        if (
          config.data &&
          !config.data.json &&
          !config.headers["Content-Type"]
        ) {
          if (payloadMethods.includes(config.method)) {
            config.headers["Content-Type"] =
              "application/x-www-form-urlencoded";
          }
        } else {
          config.headers["Content-Type"] = "application/json";
          if (config.data && config.data.json) delete config.data.json;
        }
      }
      return config;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    }
  );
  axios.interceptors.response.use(
    function (response) {
      return response.data;
    },
    function (err) {
      return Promise.reject(err);
    }
  );
}
